import { Paper } from "@material-ui/core";
import React from "react"; 
import Layout from "../components/Layout";
const styles = require("../styles/common.module.css");

const AcceptInvite = () => {
    return (
        <Layout>
            <div className = {styles.cls_InvitePageWrapper}>
                <Paper>
                    <div className = {styles.cls_InvitePage}>
                        <div className = {styles.cls_InviteHeader}>
                            <div className = {styles.cls_InviteAppName}> App Name </div>
                            <div className = {styles.cls_InviteAppPositon}>Role</div>
                        </div>
                        <div className = {styles.cls_InivteBody}>
                            Accept the invite to become a collaborator
                        </div>
                        <div className = {styles.cls_AcceptBtnWrapper}>
                            <div className = {styles.cls_DeclineBtn}>Decline</div>
                            <div className = {styles.cls_AccentBtn}>Accept</div>
                        </div>
                    </div>
                </Paper>
            </div>
        </Layout>
    )
}

export default AcceptInvite